import React, { useState } from 'react';
import { IconButton, RegularButton, type RegularButtonSize } from 'fronton-react';
import { useTranslation } from 'react-i18next';
import { ShoppingCartIcon } from '@fronton/icons-react';
import { css, cx } from 'linaria';

export const ADD_TO_CART_BTN_QA = 'product-cart-button';

export const ProductCartButton: React.FC<{
  className?: string;
  isActive: boolean;
  size?: RegularButtonSize;
  onClick: () => void;
}> = ({ className, size = 'm', isActive, onClick }) => {
  const { t } = useTranslation();
  const [hoveredOnce, setHoveredOnce] = useState(false);

  const onMouseLeave = () => {
    if (isActive && !hoveredOnce) setHoveredOnce(true);
  };

  return (
    <>
      <RegularButton
        data-qa={ADD_TO_CART_BTN_QA}
        className={cx(className, desktopButtonStyle)}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        aria-label={t('ui.aria-label.add-to-cart')}
        variant="primary"
        size={size}
        wide
      >
        {t('ui.button.add-to-cart')}
      </RegularButton>

      <IconButton
        data-qa={ADD_TO_CART_BTN_QA}
        className={cx(className, mobileIconStyle)}
        onClick={onClick}
        onMouseLeave={onMouseLeave}
        aria-label={t('ui.aria-label.add-to-cart')}
        variant="primary"
        invert
        size="l"
      >
        <ShoppingCartIcon type="outline" size="l" color="text-primary" />
      </IconButton>
    </>
  );
};

const mobileIconStyle = css`
  display: none;

  @media (max-width: 400px) {
    display: block;
  }
`;

const desktopButtonStyle = css`
  display: block;

  @media (max-width: 400px) {
    display: none;
  }
`;
